@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"













































































.video-card
  display: flex
  flex-direction: column
  align-items: flex-start
  border-radius: 8px
  backface-visibility: hidden
  position: relative
  height: 100%
  width: 100%
  padding: 8px 0
  box-sizing: border-box
  cursor: pointer
  +anim(200ms)

  +mq-s
    padding: 20px 0

  &.tour-active
    &:before
      width: calc(100% + 15px)
      height: calc(100% + 15px)
      background: $color-ice

  &__video-id
    display: none

  &:hover
    +anim(200ms)
    box-shadow: 0px 5px 12px rgba(159, 159, 212, 0.35)

  &__header
    width: 100%
    box-sizing: border-box
    align-items: center
    display: flex

    &__badge
      background: none !important
      padding-left: 5px
      display: block
      width: 100%

  .card__title
    width: 100%
    box-sizing: border-box
    color: $color-main
    padding-top: 4px

    h2
      font-size: 16px
      color: $color-main

      +mq-s
        bottom: 20px

    h2
      +mq-m
        font-size: 18px

      +mq-s
        font-size: 18px

  .badge
    text-align: left
    padding: 3px

  &__thumbnail
    border-radius: 3%
    width: 100%
    object-fit: contain

    +mq-m
      max-width: 100%

    +mq-s
      max-width: 100%

.duration-frame
  position: relative
  min-height: 146px
  min-width: 260px
  width: 100%
  border-radius: 3%
  font-size: 0

  +mq-s
    min-height: 185px
    min-width: 330px

  +mq-xs
    min-height: 146px
    min-width: 260px

.video-duration
  padding: 0 4px
  height: 26px
  position: absolute
  bottom: 6px
  right: 6px
  color: #FFFFFF
  background-color: $color-ink
  border-radius: 5px
  letter-spacing: .44px
  font-size: 13px
  font-weight: 600
  line-height: 1.8rem
  text-align: center
  opacity: 0.9
