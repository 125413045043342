@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"
































.empty-state
    display: flex
    align-items: center
    flex-direction: column
    padding: 24px
    text-align: center

    button
        h5
            padding-left: 10px

    &__icon
        height: 250px
        padding: 12px 0
        color: $color-main

        +mq-s
            height: 200px
    h4
        margin-top: 15px
        color: $color-main

    &__text
        padding: 12px 0
        margin: 10px 0

