@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"























.text-video-skeleton
  display: flex
  flex-direction: column
  margin-top: 10px

  .skeleton-bone
    width: 85% !important
    left: 5%
    margin: 5px 0

.video-card-skeleton
  border-radius: 8px
  backface-visibility: hidden
  box-shadow: 0 1px 3px rgba(0,0,0,.25)
  background: $color-ink-light
  padding: 8px 0
  cursor: pointer
  background-color: white

  +mq-s
    padding: 35px 0

  &:hover
    +anim(200ms)
    box-shadow: 0 6px 12px rgba(0,0,0,.35)

  .video-skeleton
    width: 100%
    box-sizing: border-box
    align-items: flex-start
    flex-direction: column
    display: flex
    min-height: 52px

    .skeleton-bone
      height: 25px !important
      width: 90% !important
      left: 5%
      margin: 10px 0

    .skeleton-bone:first-child
      width: 30% !important

    span
      background: none !important

    +mq-s
      flex-direction: column !important
      text-align: center !important
      min-height: 0
      width: 90%

  .card__header
    width: 100%
    box-sizing: border-box
    align-items: center
    display: flex

    span
      background: none !important
      padding-left: 5px

  .badge
    text-align: left

  &__thumbnail
    border-radius: 3%
    width: 280px

    +mq-s
      max-width: 100%
