@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/sass/abstracts/mixins"


















































































































































.videos-body
  .wrapper
    min-height: 80vh
    background: $color-ice

    +mq-s
      margin: 0
      max-width: 100%

  &__title
    padding: 50px 64px 0
    display: flex

    +mq-s
      padding: 40px 24px 12px

    &__label
      font-size: 20px
      color: $color-main
      padding-left: 5px

  &__video-list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 32px
    padding: 12px 64px

    +mq-l
      grid-template-columns: repeat(2, 1fr)
      grid-gap: 24px

    +mq-s
      grid-template-columns: repeat(1, 1fr)
      grid-gap: 16px
      padding: 12px 24px 12px 24px
